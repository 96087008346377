<template>
  <div>
    <div class="title">薪资报表</div>

    <el-divider></el-divider>

    <!-- table -->
    <div class="table_title">月度薪酬报表</div>
    <el-table
      :data="summaryTableData"
      :header-cell-style="tableHeaderStyle"
      style="width: 100%;"
    >
      <el-table-column prop="name" label="报表名称"> </el-table-column>
      <el-table-column prop="describe" label="描述"> </el-table-column>
      <el-table-column label="操作">
        <div class="operations">
          <div>导出</div>
        </div>
      </el-table-column>
    </el-table>

    <div class="table_title">季度薪酬报表</div>
    <el-table
      :data="DetailedTableData"
      :header-cell-style="tableHeaderStyle"
      style="width: 100%;"
    >
      <el-table-column prop="name" label="报表名称"> </el-table-column>
      <el-table-column prop="describe" label="描述"> </el-table-column>
      <el-table-column label="操作">
        <div class="operations">
          <div>导出</div>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      summaryTableData: [
        {
          name: "工资明细表",
          describe: "员工当月各科目工资金额明细情况",
        },
        {
          name: "发薪银行对接表",
          describe: "企业使用网上银行发薪时的工资导入表",
        },
        {
          name: "人员信息表（个税申报）",
          describe: "企业使用自然人税收管理系统申报员工个税时的人员信息导入表",
        },
      ],
      DetailedTableData: [
        {
          name: "各部门人力成本汇总表",
          describe: "员工当月每天打卡时间的明细展示",
        },
      ],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.el-table{
  margin-bottom: 60px;
}

.operations {
  display: flex;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
